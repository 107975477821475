@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'JejuGothic-Regular';
  src: url('../public/JejuGothic-Regular.ttf');
}

@font-face {
  font-family: 'IstokWeb-Regular';
  src: url('../public/IstokWeb-Regular.ttf');
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('../public/Inter-Regular.otf');
}

@font-face {
  font-family: 'Baloo-Regular';
  src: url('../public/Baloo-Regular.ttf');
}

@font-face {
  font-family: 'FrancoisOne';
  src: url('../public/FrancoisOne.ttf');
}

@font-face {
  font-family: 'JuliusSansOne';
  src: url('../public/JuliusSansOne-Regular.ttf');
}

@font-face {
  font-family: 'Jua';
  src: url('../public/Jua-Regular.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
