.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.glasses {
  animation: wiggle infinite 2s linear;
}

.special-text {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@keyframes wiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(10deg);
  }

  50% {
    -webkit-transform: rotate(0deg);
  }

  75% {
    -webkit-transform: rotate(-10deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }

}

.slider {
  overflow-y: hidden;
  max-height: 500px;
  /* approximate max height */

  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slider.closed {
  max-height: 0;
}

@keyframes typing {
  from {
    width: 100%
  }

  to {
    width: 0
  }

}

.container {
  position: relative;
  font-family: Consolas, monospace;
}

.typing {
  text-align: center;
  z-index: -1;
}

@media (min-width: 1024px) {
  .hiders {
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

/* .hiders {
margin: 0;
position: absolute;
top: 0;
width: 100%;
} */

.hiders p {
  font-size: 1.875rem
    /* 30px */
  ;
  line-height: 2.40rem
    /* 36px */
  ;
  position: relative;
  clear: both;
  margin: 0;
  float: right;
  /* makes animation go left-to-right */
  width: 0;
  /* graceful degradation: if animation doesn't work, these are invisible by default */
  background-color: #F2F7FE;
  /* same as page background */
  animation: typing 2s steps(30, end);
  animation-fill-mode: both;
  /* load first keyframe on page load, leave on last frame at end */
}

.hiders p:nth-child(2) {
  animation-delay: 2s;
}

.hiders p:nth-child(3) {
  animation-delay: 4s;
}

.text-shadow {
  text-shadow: 2px 2px 4px #00000083;
}

.disable-select {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.carousel_list {
  align-items: center;
  display: grid;
  /* grid-row-gap: 1rem; */
  grid-template-rows: 1fr auto;
  overflow: hidden;
  list-style-type: none;
}

.carousel_item {
  grid-column: 1;
  grid-row: 1;
  line-height: 1.2;
  text-align: center;
  transition: transform 0.2s;
}

.carousel_item.active {
  transform: translateX(0);
}

.carousel_item.left {
  transform: translateX(-110%);
}

.carousel_item.right {
  transform: translateX(110%);
}

.carousel_img {
  border-radius: 100%;
  width: 65vh;
  height: 65vh;
}

.main {
  background-image: url(./public/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.div-shadow {
  box-shadow: 6px 22px 42px 0px rgba(242, 242, 242, 1);
  background: linear-gradient(180deg, #F9FBFF 0%, #FFF8FF 100%);
}

.accomplish {
  background: linear-gradient(122.6deg, rgba(230, 240, 255, 0.42) -3.13%, rgba(255, 255, 255, 0.42) 99.34%);
}

.card {
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  width: auto;
  height: 200px;
}

.flipped {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}
